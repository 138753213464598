import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { AppGuard } from '@core/guards/auth.guard'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { setUserActivated } from '@core/utils/auth-stuff'
import { getMonitoredBusiness, setBusinessLogo } from '@core/utils/user-stuff'
import { Observable, tap, of } from 'rxjs'
import { AdminBusinessDetail } from '@models/admin-business.model'
import { Store } from '@ngrx/store'
import { setAllPermissions } from 'src/app/shared/states/permissions/permission.action'
import { PermissionList } from '@models/permission.model'

@Injectable({
  providedIn: 'root',
})
export class UserInfoResolver implements Resolve<any> {
  constructor(
    private _userRepository: UserRepository,
    private _cookieService: CustomCookieService,
    private _storeState: Store<PermissionList>,
    protected appGuard: AppGuard
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const monitoredBusiness: AdminBusinessDetail = getMonitoredBusiness()
    if (!monitoredBusiness?.storeId) {
      return of(false)
    }
    return this._userRepository.getUserInfo().pipe(
      tap({
        next: (response: any) => {
          this._storeState.dispatch(setAllPermissions(response.data.role.permissions as any))
          setUserActivated(this._cookieService, response.data?.activated || false)
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.appGuard.checkAccess(httpErrorResponse.status)
          return httpErrorResponse.error
        },
      })
    )
  }
}
