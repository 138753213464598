import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { AdminBusinessDetail } from 'src/app/admin/business/ui/admin-business.model'
import { ResponseModel } from '@core/models/common.model'
import {
  AdminModel,
  ForgotPassword,
  ForgotPasswordForm,
  ForgotPasswordResponse,
  LoginModel,
  LoginResponse,
  MerchantAuth,
  MerchantUpdatePassword,
  MethodListResponse,
  UserContent,
} from '@core/models/user.model'
import { AdminBusinessService } from '@services/admin-business/admin-business.service'
import { AuthService } from '@services/auth/auth.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getAdminPrivilage, getAuthKey } from '@core/utils/auth-stuff'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { BranchRepositoryService } from 'src/app/feature/branch/data-access/repository/branch-repository.service'
import { AdminBusinessRespositoryService } from 'src/app/admin/business/data-access/repository/admin-business-repository/admin-business-respository.service'

@Injectable()
export class UserRepository {
  userIdToModelMap: Map<string, UserContent> = new Map<string, UserContent>()
  adminIdToModelMap: Map<string, ResponseModel<AdminModel>> = new Map<string, ResponseModel<AdminModel>>()

  constructor(
    private _httpClient: HttpClient,
    private _appGuard: AppGuard,
    private _authService: AuthService,
    private _customCookieService: CustomCookieService,
    private _businessService: AdminBusinessService,
    private _businessRepository: AdminBusinessRespositoryService,
    private _branchRepository: BranchRepositoryService
  ) {}

  login(loginModel: LoginModel): Observable<LoginResponse> {
    return this._httpClient.post(AppSettings.USER_LOGIN, loginModel).pipe(
      map((response: LoginResponse | any) => {
        if (response.code == 'OK') {
          this._authService.setUserKey(response.data.userKey)
          this._authService.setDeviceKey(response.data.deviceKey)
          this._authService.setUser(response.data.user)
        }

        return response
      })
    )
  }

  merchantLogin(data: MerchantAuth): Observable<MerchantAuth | any> {
    return this._httpClient.post(AppSettings.MERCHANT_LOGIN, data).pipe(
      map((response: MerchantAuth | any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this._appGuard.checkAccess(error.status)
          }
        },
      })
    )
  }

  merchantLoginGoogle(data: any): Observable<MerchantAuth | any> {
    return this._httpClient.post(AppSettings.MERCHANT_LOGIN_GOOGLE, data).pipe(
      map((response: MerchantAuth | any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this._appGuard.checkAccess(error.status)
          }
        },
      })
    )
  }

  forgotPassword(forgotPasswordModel: ForgotPasswordForm): Observable<ForgotPasswordResponse> {
    return this._httpClient.post(AppSettings.OAUTH_FORGOT_PASSWORD_URL_V2, forgotPasswordModel).pipe(
      map((response: LoginResponse | any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
        },
      })
    )
  }

  forgotPasswordVerify(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    const data = new FormData()
    data.append('token', token)
    return this._httpClient
      .post(
        `${AppSettings.OAUTH_FORGOT_PASSWORD_URL_V2}/${AppSettings.FORGOT_PASSWORD_VERIFY_V2}`,
        { token: token },
        { headers: headers }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }

  forgotPasswordConfirm(newPassword: ForgotPassword): Observable<ForgotPassword> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient
      .post(`${AppSettings.OAUTH_FORGOT_PASSWORD_URL_V2}/${AppSettings.FORGOT_PASSWORD_CONFIRM_V2}`, newPassword, {
        headers: headers,
      })
      .pipe(
        map((response: ForgotPassword | any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }

  verifyMerchant(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this._httpClient.post(AppSettings.VERIFY_MERCHANT, { token: token }, { headers: headers }).pipe(
      map((response: any) => response),
      tap(
        (response) => response,
        (err: any) => {
          if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
        }
      )
    )
  }

  resendVerifyMerchant(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._authService.getAuthKey())
    return this._httpClient
      .post(`${AppSettings.MERCHANT_UTILITIES}/${AppSettings.RESEND_VERIFY_MERCHANT}`, null, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }

  emailVerified(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + this._authService.getAuthKey())
    return this._httpClient
      .get(`${AppSettings.MERCHANT_UTILITIES}/${AppSettings.EMAIL_VALIDATED}`, { headers: headers })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }

  paymentMethod(): Observable<MethodListResponse> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + this._authService.getAuthKey())
    return this._httpClient.get(AppSettings.PAYMENT_METHOD, { headers: headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response: any) => {
          // Uncomment this to filtering disabled payment method
          // response.data = response.data = response.data.filter((payment: any) => payment.disabled == false)
          return response
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
        },
      })
    )
  }

  paymentType(): Observable<MethodListResponse> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + this._authService.getAuthKey())
    return this._httpClient.get(AppSettings.PAYMENT_TYPE, { headers: headers }).pipe(
      map((response: MethodListResponse | any) => response),
      tap({
        next: (response: any) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
        },
      })
    )
  }

  templateList(): Observable<MethodListResponse> {
    return this._httpClient.get(AppSettings.MERCHANT_TEMPLATE).pipe(
      map((response: MethodListResponse | any) => response),
      tap({
        next: (response: any) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
        },
      })
    )
  }

  merchantUpdatePassword(merchantPassword: MerchantUpdatePassword): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._authService.getAuthKey())
    return this._httpClient
      .post(
        `${AppSettings.MERCHANT_UTILITIES_URL_V3}/${AppSettings.MERCHANT_UTILITIES_UPADTE_PASSWORD}`,
        merchantPassword,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }

  getUserInfo(): Observable<MerchantAuth | any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    return this._httpClient.get(AppSettings.OAUTH_ACCOUNT_URL, { headers: headers }).pipe(
      map((response: MerchantAuth | any) => response),
      tap({
        next: (response) => response.data,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) {
            this._appGuard.checkAccess(error.status)
          }
        },
      })
    )
  }

  getStoreInfo() {
    const monitoredBusiness: AdminBusinessDetail | null = this._businessService.getMonitoredBusiness() || null

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + getAuthKey(this._customCookieService))
    let url: string = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}`

    let storeId: number = monitoredBusiness?.storeId || 0
    if (monitoredBusiness?.allStore === true) {
      storeId = 0
    }

    let businessId: number = monitoredBusiness?.id || 0
    
    if (getAdminPrivilage() === true) {
      url = `${url}?${AppSettings.BUSINESS_UTILITIES_ID}=${businessId}&${AppSettings.BRANCH_STORE_ID}=${storeId}`
    } else {
      url = `${url}?${AppSettings.BRANCH_STORE_ID}=${storeId}`
    }

    return this._httpClient
      .get(
        url,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this._appGuard.checkAccess(err.status)
          },
        })
      )
  }
}
