import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule, enableProdMode } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppGuard } from '@core/guards/auth.guard'
import { HttpResponseErrorInterceptor } from '@core/interceptors/http-response-error-interceptor/http-response-error.interceptor'
import { AdminAuthResolver } from '@core/resolvers/admin-auth-resolvers/admin-auth.resolver'
import { SidebarService } from '@layouts/pages/sidebar/sidebar.service'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AuthService } from '@services/auth/auth.service'
import { ViewService } from '@services/view/view.service'
import { randomString } from '@core/utils/string'
import { ClipboardModule } from 'ngx-clipboard'
import { CookieModule } from 'ngx-cookie'
import { NgxSpinnerModule } from 'ngx-spinner'
import { AppComponent } from './app.component'
import { routing } from './app.routing'
import { SharedModule } from './shared/shared.module'
import * as Sentry from '@sentry/angular-ivy'
import { NgOptimizedImage } from '@angular/common'
import { UserInfoResolver } from '@core/resolvers/user-info-resolver/user-info.resolver'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { IdekuNewCurrencyPipe } from '@pipes/ideku-new-currency/ideku-new-currency.pipe'
import { StoreModule } from '@ngrx/store'
import * as permissionReducer from './shared/states/permissions/permission.reducer'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?${randomString()}`)
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    StoreModule.forRoot({ permissions: permissionReducer.reducer }),
    routing,
    HttpClientModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: 'en_US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CookieModule.forRoot(),
    ClipboardModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SharedModule,
    // NgxDaterangepickerMd.forRoot(),
  ],
  providers: [
    AuthService,
    ViewService,
    AdminAuthResolver,
    AppGuard,
    SidebarService,
    NgOptimizedImage,
    UserRepository,
    UserInfoResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    IdekuNewCurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
