import { Action, ActionReducer, createReducer, on } from '@ngrx/store'
import * as PermissionActions from './permission.action'
import { PermissionList } from '@models/permission.model'

export let initPermissionList!: PermissionList
const permissionReducer: ActionReducer<PermissionList> = createReducer(
  initPermissionList,
  on(
    PermissionActions.setAllPermissions,
    (state, permissions) => (initPermissionList = permissions)
  ),
  on(
    PermissionActions.deleteAllPermissions,
    (state) => state = {} as PermissionList
  )
)

export function reducer(state: PermissionList | undefined, action: Action) {
  return permissionReducer(state, action)
}