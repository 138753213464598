import { IEndpoint } from '../models/integration.model'

export const INTEGRATION_PAYMENT_ENDPOINTS: IEndpoint = {
  connection: {
    merchant: '/api-payment/portal/v1/integration/payment/payment-online',
    monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/integration/payment/payment-online',
  },
  paymentAvailable: {
    merchant: '/api-payment/portal/v1/integration/payment/payment-online',
    monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/integration/payment/payment-online',
  },
  paymentMethod: {
    merchant: '/api-payment/portal/v1/integration/payment/payment-online/method',
    monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/integration/payment/payment-online/method',
  },
  paymentFee: {
    superAdmin: '/integration/payment/payment-online/method/fee'
  },
  kpay: {
    detailAccount: {
      superAdmin: '/api-kpay/account/:storeId',
    },
    listTerminal: {
      merchant: '/api-kpay/terminal',
      monitorSuperAdmin: '/terminal',
    },
    listStore: {
      superAdmin: '/api-kpay/account',
    },
    updateAccount: {
      superAdmin: '/api-kpay/account/update',
      merchant: '/api-kpay/account/update',
    },
    terminalStatus: {
      superAdmin: '/api-kpay/static-data/terminal-status',
    },
    register: {
      merchant: '/api-kpay/account/register',
      monitorSuperAdmin: '',
    },
    integrationTerminalStatus: {
      superAdmin: '/api-kpay/static-data/integration-status'
    }
  },
  gdc: {
    listStore: {
      superAdmin: '/api-payment/portal/v1/admin/gdc/accounts'
    },
    terminalStatus: {
      superAdmin: '/api-payment/portal/v1/admin/options/gdc-account-status'
    },
    integrationTerminalStatus: {
      superAdmin: '/api-payment/portal/v1/admin/options/payment-integration-status'
    },
    updateAccount: {
      superAdmin: '/api-payment/portal/v1/admin/gdc/accounts/:storeId'
    },
    lastWithdraw: {
      merchant: '/api-payment/portal/v1/gdc/withdrawals/last-withdrawal-details',
      monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/gdc/withdrawals/last-withdrawal-details'
    },
    withdraw: {
      merchant: '/api-payment/portal/v1/gdc/withdrawals',
      monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/gdc/withdrawals',
      superAdmin: '/api-payment/portal/v1/admin/gdc/withdrawals'
    },
    withdrawStatus: {
      superAdmin: '/api-payment/portal/v1/admin/options/withdrawal-status'
    },
    uploadFile: {
      superAdmin: '/api-payment/portal/v1/admin/upload/withdrawal-receipt'
    },
    withdrawForm: {
      superAdmin: '/api-payment/portal/v1/admin/gdc/withdrawals/:withdrawId/approve'
    }
  }
}
